import React from "react";
import Contactform from "./form";


const ContactContent = () => {
    
    return <>
        <div>
            <div className="overflow-hidden">
                <div className="container content-space-1 content-space-lg-4">
                
                    <div className="row">
                        <div className="col-lg-6 mb-10 mb-lg-0">
                            <div className="pe-lg-10">
                                <div className="mb-2">
                                    <h3>Notre bureau</h3>
                                </div>

                                <div className="mb-2">
                                    <img className="img-fluid" src="/assets/img/580x480/img3.jpg"
                                         alt="Image Description" />
                                </div>

                                <address>
                                    <span className="d-block fs-3 fw-bold text-dark mb-2">addresse:</span>
                                    Afrisends.<br />
                                    ACI 2000<br />
                                    Bamako, Mali <br />
                                    +33767622662 <br />
                                </address>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="position-relative">
                                <div className="card card-lg">
                                    <div className="card-body">
                                    <Contactform />
                                    </div>
                                </div>
                                <figure className="position-absolute bottom-0 end-0 zi-n1 d-none d-md-block mb-n10"
                                        style={{width: '15rem', marginRight: '-8rem'}}>
                                    <img className="img-fluid" src="/assets/svg/illustrations/grid-grey.svg"
                                         alt="Image Description" />
                                </figure>

                                <figure className="position-absolute bottom-0 end-0 d-none d-md-block me-n5 mb-n5"
                                        style={{width: '10rem'}}>
                                    <img className="img-fluid" src="/assets/svg/illustrations/plane.svg"
                                         alt="Image Description" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ContactContent;
