import React from "react";
import Layout from "../components/layout/Layout";
import ContactContent from "../components/contact/ContactContent";
const Contact = () => {
    return <>
    <Layout title={'Contact'}>
        <ContactContent/>
    </Layout>
    </>
}
export default Contact;