import {yupResolver} from '@hookform/resolvers/yup';
import React, {useState} from 'react'
import {useForm} from 'react-hook-form';
import {contactSchema} from '../../util/yuSchema';
import {post} from "../../services/BaseService";
import {contactUsUrls} from "../../util/api_constant";
import {getConnected} from "../../util/helper";

export default function Contactform() {
    let connected = getConnected();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<any>({
        mode: 'onTouched',
        resolver: yupResolver(contactSchema)
    });
    /*errors,*/
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit = async (data: any) => {
        setIsSubmitted(false);
        setIsSubmitting(true);
        post(contactUsUrls.base, data)
            .then((r) => {
                setIsSubmitted(true);
                setIsSubmitting(false);
                reset();
            }).catch(() => {
            setIsSubmitting(false);
        })

    };

    return (
        <div>
            <h4 className="mb-4">Remplissez le formulaire</h4>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div> {isSubmitted && <div className="alert alert-success" role="alert">
                    <strong>Votre message a bien été envoyé.</strong> <br/>
                    Nous reviendrons vers vous dans les plus bref délai, Merci.
                </div>}</div>
                <div className="row">
                    <div className="col-sm-6 mb-4 mb-sm-0">
                        <div className="mb-4">
                            <label className="form-label" htmlFor="firstName">Prénom
                            </label>
                            <input type="text" className="form-control"
                                // name="contactsFormNameFirstName"
                                   required
                                   defaultValue={connected?connected.firstName:null}
                                   id="firstName" placeholder="Moussa"
                                   aria-label="Moussa"
                                   {...register("firstName")}
                                   disabled={isSubmitted}/>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="mb-4">
                            <label className="form-label" htmlFor="lastName">Nom de famille
                            </label>
                            <input type="text" className="form-control"
                                //name="contactsFormNameLastName"
                                   id="lastName" placeholder="Traore"
                                   aria-label="Traore"
                                   defaultValue={connected?connected.lastName:null}
                                   {...register("lastName")}
                                   disabled={isSubmitted}/>
                        </div>
                    </div>

                </div>


                <div className="row">
                    <div className="col-sm-6 mb-4 mb-sm-0">
                        <div className="mb-4">
                            <label className="form-label"
                                   htmlFor="contactsFormCompany">Code Afrisends</label>
                            <input type="text" className="form-control"
                                //  name="contactsFormNameCompany"
                                   id="company"
                                   placeholder="AFRI001" aria-label="Afrisends"
                                   defaultValue={connected?connected.afrisendsId:null}
                                   {...register("afrisendsId")}
                                   disabled={isSubmitted}/>
                        </div>

                    </div>

                    <div className="col-sm-6">
                        <div className="mb-4">
                            <label className="form-label"
                                   htmlFor="webSite">Téléphone</label>
                            <input type="text" className="form-control"
                                // name="webSite"
                                   id="webSite"
                                   placeholder="+223 76000000"
                                   defaultValue={connected?connected.fullPhoneNumber:null}
                                   {...register("phoneNumber")}
                                   disabled={isSubmitted}/>
                        </div>
                    </div>

                    <div className="mb-4 col-sm-6">
                        <label className="form-label" htmlFor="subject">
                            Objet</label>
                        <input type="text" className="form-control"
                            // name="email"
                               id="subject"
                               required
                               placeholder="Objet"
                               {...register("subject")}
                               disabled={isSubmitted}/>

                    </div>
                    <div className="mb-4 col-sm-6">
                        <label className="form-label" htmlFor="email">
                            Email</label>
                        <input type="text" className="form-control"
                            // name="email"
                               id="email"
                               required
                               defaultValue={connected?connected.username:null}
                               placeholder="votre email valide" aria-label="email@afrisends.com"

                               {...register("email")}
                               disabled={isSubmitted}/>
                        <div className="validation">
                            {errors.email && <div className="help-block with-errors"
                                                  style={{"color": "#e20c0c"}}>{errors.email.message}</div>}
                        </div>
                    </div>
                </div>


                <div className="mb-4">
                    <label className="form-label"
                           htmlFor="subject">Votre message</label>
                    <textarea className="form-control"
                        //name="contactsFormNameDetails"
                              id="subject"
                              placeholder="Comment pouvons-nous vous aider?"
                              aria-label="Comment pouvons-nous vous aider?"
                              rows={4}
                              {...register("message")}
                              disabled={isSubmitted}

                    ></textarea>
                    <div className="validation">
                        {errors.subject && <div className="help-block with-errors"
                                                style={{"color": "#e20c0c"}}>{errors.subject.message}</div>}
                    </div>
                </div>

                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg" disabled={isSubmitted}>
                        {isSubmitting ? <div><span className="spinner-border spinner-border-sm mr-3" role="status"
                                                   aria-hidden="true"/>&nbsp;&nbsp;Envoi en cours</div> :
                            <span>Envoyer</span>}
                    </button>
                </div>
            </form>
        </div>
    )
}
